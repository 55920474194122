import React, { Component } from "react"
import Seo from "@components/seo"
import Layout from "@layout"
import Header from "@layout/header/header-one"
import Footer from "@layout/footer/footer-one"
import AboutArea from "@containers/index-processing/about-area"
import ContactArea from "@containers/index-infotechno/contact-area"

import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import PageHeader from "@components/pageheader";

import slide3 from "@assets/images/rehabilitation/P3130243.jpg"
import slide6 from "@assets/images/rehabilitation/2.jpg"

import slide4 from "@assets/images/rehabilitation/3.jpg"
import slide5 from "@assets/images/rehabilitation/4.jpg"
const RehabilitationOfHeritageBuildings = ({ location,pageContext }) => {
  const intl = useIntl()
  const serviceTitles = intl.formatMessage({
    id: "rehabilitation_of_heritage_buildings_and_historical_sites",
  })
  const content = [
    {
      image: slide3,
    },
    // {
    //   image: slide2,
    // },
    {
      image: slide4,
    },
    {
      image: slide5,
    },
    {
      image: slide6,
    },
  ]

  const srviceTexts = [
    {
      text: <FormattedMessage id="studying_the_current_status" />,
    },
    {
      text: <FormattedMessage id="determining_the_appropriate_treatment" />,
    },
  ]
  return (
    <Layout location={location}>
      <Seo title="Services" />
      <Header />
      <main className="site-wrapper-reveal">
        {/* <HeroArea /> */}
        <PageHeader
			pageContext={pageContext}
			location={location}
			title="IT Services"
      pageTitle="services"
		/>
        <AboutArea content={content} srviceTexts={srviceTexts} stopPointer="stopPointer"  serviceTitles={serviceTitles} studyRequestUrl="/study-request-reahbitition" buttonTextRequest="study_request" isStudyRequest={true} />

        <ContactArea />
      </main>
      <Footer />
    </Layout>
  )
}

export default RehabilitationOfHeritageBuildings
